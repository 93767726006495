import skinclubImage from '@/app/assets/images/projects-cards/skinclub.png';
import skinclubImageWebp from '@/app/assets/images/projects-cards/skinclub.webp';
import fireImage from '@/app/assets/images/projects-cards/fireImage.png';
import fireImageWebp from '@/app/assets/images/projects-cards/fireImage.webp';
import kaiImage from '@/app/assets/images/projects-cards/kaiImage.png';
import kaiImageWebp from '@/app/assets/images/projects-cards/kaiImage.webp';
import tradeImage from '@/app/assets/images/projects-cards/tradeImage.png';
import tradeImageWebp from '@/app/assets/images/projects-cards/tradeImage.webp';

export const works = [
    {
        image: skinclubImage,
        imageWebp: skinclubImageWebp,
        alt: 'Slide 1',
        title: 'SkinClub - портал для розыгрыша скинов',
        duration: 'Срок выполнения работы: ',
        date: '40 дней',
        buttonText: 'Смотреть проект',
        projectLink:
            'https://webteamstorm.ru/projects/skin-club-odnostranichnuj-sajt-dlya-prognozov-counter-strike/',
    },
    {
        image: fireImage,
        imageWebp: fireImageWebp,
        alt: 'Slide 2',
        title: 'Одностраничник Center of Fireproof Tech',
        tasksTitle: 'Поставленные задачи:',
        duration: 'Срок выполнения работы: ',
        date: '20 дней',
        buttonText: 'Смотреть проект',
        projectLink:
            'https://webteamstorm.ru/projects/myltiyazuchnuyi_lending_protivopojarnuh_tehnologiyi_28/​​​​​​​',
    },
    {
        image: kaiImage,
        imageWebp: kaiImageWebp,
        alt: 'Slide 3',
        title: 'Введение в предпринимательство',
        duration: 'Срок выполнения работы: ',
        date: '10 дней',
        buttonText: 'Смотреть проект',
        projectLink:
            'https://webteamstorm.ru/projects/lending_vvedenie_v_predprinimatelstvo_10/',
    },
    {
        image: tradeImage,
        imageWebp: tradeImageWebp,
        alt: 'Slide 4',
        title: 'Оказание финансовый услуг трейдинвест',
        duration: 'Срок выполнения работы: ',
        date: '15 дней',
        buttonText: 'Смотреть проект',
        projectLink:
            'https://webteamstorm.ru/projects/odnostranichnuyi_lending_treyid-invest_31/',
    },
];
