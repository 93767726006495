import cn from 'classnames';
import cls from './Button.module.scss';
import loaderImage from '@/app/assets/images/loader.svg';

const mapColorToClass = {
    white: cls.white,
};

export const Button = (props) => {
    const {
        children,
        className,
        color,
        type = 'button',
        isLoading = false,
        ...otherProps
    } = props;

    const colorClass = mapColorToClass[color];

    return (
        <button
            className={cn(className, cls.button, colorClass)}
            type={type}
            disabled={isLoading}
            {...otherProps}
        >
            {isLoading ? (
                <img
                    className={cls.loader}
                    src={loaderImage}
                    alt={'Загрузка'}
                />
            ) : (
                children
            )}
        </button>
    );
};
