import cn from 'classnames';
import cls from './Tariffs.module.scss';
import { Title } from '@/components/common/Title/Title';
import { TariffBasic } from '@/components/common/Tariffs/TariffBasic/TariffBasic';
import { TariffStandard } from '@/components/common/Tariffs/TariffStandard/TariffStandard';
import { TariffAdvanced } from '@/components/common/Tariffs/TariffAdvanced/TariffAdvanced';
import { TariffBudget } from '@/components/common/Tariffs/TariffBudget/TariffBudget';

export const Tariffs = (props) => {
    const { className } = props;

    return (
        <section className={cn(className, cls.tariffs)} id={"tariff"}>
            <div className={cls.header}>
                <Title align={'center'}>Наши тарифы</Title>
            </div>

            <TariffBasic />
            <TariffStandard />
            <TariffAdvanced />
            <TariffBudget />
        </section>
    );
};
