export const tariffsBasic = [
    'До 7-ми информационных блоков',
    'Оригинальный дизайн от художника (2 концепта)',
    'Адаптивная верстка (ПК / Моб / Планшет)',
    'Кроссбраузерная верстка',
    'Добавление анимации и эффектов',
    'Установка модулей и обработчиков форм',
    'Сопровождение и поддержка',
    'Интеграция с CRM-системой',
];

export const tariffsStandard = [
    'до 10 информационных блока',
    'Адаптивная верстка (ПК / Моб / Планшет)',
    'Оригинальный дизайн от художника (4 концепта)',
    'Адаптивная верстка (ПК / Моб / Планшет)',
    'Кроссбраузерна верстка',
    'Добавление анимации и эффектов',
    'Установка модулей и обработчиков форм',
    'Сопровождение и поддержка',
    'Интеграция с CRM-системой',
];

export const tariffsStandardTwo = [
    'Подключение админ панели',
    'Настройка домена, сервера и почты',
    'Установка метрик и систем аналитики',
    'Настройка целей',
];

export const tariffsAdvanced = [
    'От 10 информационных блоков',
    'Оригинальный дизайн от художника',
    'Адаптивная верстка (ПК / Моб / Планшет)',
    'Кроссбраузерна верстка',
    'Добавление анимации и эффектов',
    'Установка модулей и обработчиков форм',
    'Сопровождение и поддержка',
    'Настройка целей',
    'Интеграция с CRM-системой',
];

export const tariffsAdvancedTwo = [
    'Написание продающих текстов',
    'Создание вариативности продающего контента',
    'Доп. оптимизация ресурсов сайта',
    'Запуск рекламной кампании в Я.Директ',
    'Подключение админ панели',
    'Настройка домена, сервера и почты',
    'Установка метрик и систем аналитики',
];

export const tariffsBudget = [
    'Шаблонный дизайн',
    'Шаблонная админ панели',
    'Быстрая реализация (3-4 дня)',
];
