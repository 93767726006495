import cn from 'classnames';
import cls from './TariffBudget.module.scss';
import { Container } from '@/components/common/Container/Container';
import { Title } from '@/components/common/Title/Title';
import { PriceText } from '@/components/common/PriceText/PriceText';
import React from 'react';
import { ButtonModal } from '@/components/common/ButtonModal/ButtonModal';
import { tariffsBudget } from '@/utils/constants/tariffs/tariffs';

import decor from '@/app/assets/images/tariffs/budget-tariff.png';
import { useInView } from 'react-intersection-observer';
import decorWebp from '@/app/assets/images/tariffs/budget-tariff.webp';
import decorTriangle from '@/app/assets/images/tariffs/triangle.svg';

export const TariffBudget = (props) => {
    const { className } = props;
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <div className={cn(className, cls.tariff)} ref={ref}>
            <Container size={'medium'} className={cls.wrapper}>
                <div className={cls.content}>
                    <div className={cls.header}>
                        <Title size={'smallTwo'}>Бюджетный </Title>
                        <PriceText>От 9999 руб</PriceText>
                    </div>

                    <ul className={cls.list}>
                        {tariffsBudget.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>

                    <div className={cls.btnWrapper}>
                        <ButtonModal
                            selectedTariff={{
                                name: 'Бюджетный',
                                price: 'От 9999 руб',
                            }}
                        >
                            Оставить заявку
                        </ButtonModal>
                    </div>
                </div>
            </Container>
            {inView && (
                <picture>
                    <source srcSet={decorWebp} type={'image/webp'} />
                    <img
                        width={635}
                        height={426}
                        className={cls.decor}
                        src={decor}
                        alt=""
                    />
                </picture>
            )}

            <img
                className={cls.triangle}
                src={decorTriangle}
                alt={'Треугольник'}
            />
        </div>
    );
};
