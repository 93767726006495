import cls from './QuestionList.module.scss';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { SvgSelector } from '@/components/common/SvgSelector/SvgSelector';
import { questions } from '@/utils/constants/questions/questions';

export const QuestionList = () => {
    return (
        <Accordion allowZeroExpanded>
            {questions.map((item, idx) => (
                <AccordionItem className={cls.item} key={idx}>
                    <AccordionItemHeading>
                        <AccordionItemButton className={cls.button}>
                            <p className={cls.question}>{item.question}</p>
                            <SvgSelector id={'arrow-small'} />
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={cls.content}>
                        <p>{item.answer}</p>
                    </AccordionItemPanel>
                </AccordionItem>
            ))}
        </Accordion>
    );
};
