import cn from 'classnames';
import { Button } from '@/components/common/Button/Button';
import PhoneInput from 'react-phone-input-2';
import React, { useState } from 'react';
import WarningMessage from '@/components/common/Modal/WarningMessage';
import ErrorMessage from '@/components/common/Modal/ErrorMessage';
import SuccessMessage from '@/components/common/Modal/SuccessMessage';
import cls from './FeedbackForm.module.scss';

export const FeedbackForm = (props) => {
    const { className } = props;

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorCheckbox, setErrorCheckbox] = useState(false);
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleNameChange = (event) => {
        const value = event.target.value;
        setName(value);
        if (value.trim().length < 4) {
            setNameError(true);
        } else {
            setNameError(false);
        }
    };

    const handlePhoneChange = (value) => {
        const numericValue = value.replace(/[^\d]/g, '');
        const formattedPhone = `+7(${numericValue.slice(1, 4)})${numericValue.slice(4, 7)}-${numericValue.slice(7, 9)}-${numericValue.slice(9)}`;

        setPhone(formattedPhone);

        if (
            !formattedPhone.trim() ||
            formattedPhone.replace(/[^\d]/g, '').length < 11
        ) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'name':
                setNameError(!value.trim());
                break;
            case 'phone':
                setPhoneError(
                    !value.trim() || value.replace(/[^\d]/g, '').length < 10,
                );
                break;
            default:
                break;
        }
    };
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        if (!name.trim() || !phone.trim()) {
            setNameError(!name.trim());
            setPhoneError(!phone.trim());
            setShowWarning(true);
        } else if (!checkboxChecked) {
            setErrorCheckbox(true);
        } else {
            setShowWarning(false);
            setErrorCheckbox(false);
            const formData = new FormData();
            formData.append('name', name);
            formData.append('phone', phone);
            formData.append('email', 'test@gmail.com');
            formData.append('source', 'LP форма заявки');
            try {
                setIsLoading(true);
                const response = await fetch(
                    'https://webteamstorm.ru/api/ajax-send-order',
                    {
                        method: 'POST',
                        body: formData,
                    },
                );
                const responseData = await response.json();
                if (response.ok) {
                    console.log(
                        'Данные успешно отправлены:',
                        responseData.message,
                    );
                    setShowSuccess(true);
                    window.ym(96769092, 'reachGoal', 'left_order');
                } else {
                    console.error(
                        'Ошибка при отправке данных на сервер:',
                        responseData.message,
                    );
                    setShowError(true);
                }
            } catch (error) {
                console.error('Ошибка при отправке данных:', error.message);
                setShowError(true);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            {showWarning && (
                <WarningMessage onClose={() => setShowWarning(false)} />
            )}
            {showError && <ErrorMessage onClose={() => setShowError(false)} />}
            {showSuccess && (
                <SuccessMessage onClose={() => setShowSuccess(false)} />
            )}

            <form
                className={cn(className, cls.form)}
                onSubmit={handleFormSubmit}
            >
                <h4 className={cls.title}>
                    Оставьте свой номер и мы с вами свяжемся в ближайшее время!
                </h4>

                <div className={cls.inputs}>
                    <div className={cls.group}>
                        <label htmlFor="phone" className={cls.label}>
                            Ваш номер <span>*</span>
                        </label>
                        <PhoneInput
                            className={cn(cls.inputField, {
                                [cls.inputError]: phoneError,
                            })}
                            country={'ru'}
                            value={phone}
                            onChange={handlePhoneChange}
                            inputProps={{
                                name: 'phone',
                                required: true,
                            }}
                        />
                    </div>
                    <div className={cls.group}>
                        <label htmlFor="name" className={cls.label}>
                            Ваше имя <span>*</span>
                        </label>
                        <input
                            type="text"
                            id="name"
                            placeholder="ФИО"
                            className={cn(cls.inputField, {
                                [cls.inputError]: nameError,
                            })}
                            value={name}
                            onChange={handleNameChange}
                            onBlur={handleBlur}
                        />
                    </div>
                </div>

                <div
                    className={cn(cls.checkboxWrapper, {
                        [cls.checkboxWrapperError]: errorCheckbox,
                    })}
                >
                    <label className={cls.checkboxLabel}>
                        <input
                            type="checkbox"
                            className={cls.realCheckbox}
                            onChange={() =>
                                setCheckboxChecked(!checkboxChecked)
                            }
                        />
                        <span className={cls.customCheckbox}></span>
                        <span>
                            Соглашаюсь с правилами обрботки персональных данных
                        </span>
                    </label>
                </div>

                {errorCheckbox && (
                    <div className={cls.checkboxError}>
                        <p>
                            *Необходимо согласие на обработка персональных
                            данных
                        </p>
                    </div>
                )}

                <div className={cls.btnWrapper}>
                    <Button
                        className={cls.btn}
                        type={'submit'}
                        isLoading={isLoading}
                    >
                        Оставить заявку
                    </Button>
                </div>
            </form>
        </>
    );
};
