import cn from 'classnames';
import cls from './Footer.module.scss';
import { Container } from '@/components/common/Container/Container';
import tgIcon from '@/assets/tg-icon.svg';
import vkIcon from '@/assets/vk-icon.svg';
import skypeIcon from '@/assets/skype-icon.svg';
import mailIcon from '@/assets/mail-icon.svg';
import phoneIcon from '@/assets/phone-icon.svg';
import React from 'react';
import logoFull from '@/app/assets/images/logo-full.svg';

export const Footer = (props) => {
    const { className } = props;

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className={cn(className, cls.footer)}>
            <Container>
                <div className={cls.top}>
                    <div className={cls.left}>
                        <div className={cls.image}>
                            <img src={logoFull} alt="Логотип" />
                        </div>
                        <div className={cls.description}>
                            <p>
                                Профессиональный коллектив по разработке веб- и
                                моб- приложений, систем управления, сайтов, игр.
                                Оказываем полный комплекс услуг от
                                прототипирования до SEO-оптимизации.
                            </p>
                            <p className={cn(cls.copy, cls.copyTablet)}>
                                Severtain © 2017-2024 | Все права защищены
                            </p>
                        </div>
                    </div>
                    <div className={cls.right}>
                        <div className={cls.socials}>
                            <a href="https://t.me/severtain" target="_blank">
                                <img
                                    src={tgIcon}
                                    alt="Telegram Icon"
                                    className="icon"
                                />
                            </a>
                            <a
                                href="https://vk.com/webteamstorm"
                                target="_blank"
                            >
                                <img
                                    src={vkIcon}
                                    alt="VK Icon"
                                    className="icon"
                                />
                            </a>
                            <a href="skype:severtaindev?chat" target="_blank">
                                <img
                                    src={skypeIcon}
                                    alt="Skype Icon"
                                    className="icon"
                                />
                            </a>
                            <a href="mailto:webteamstorm@gmail.com">
                                <img
                                    src={mailIcon}
                                    alt="Mail Icon"
                                    className="icon"
                                />
                            </a>
                            <a href="https://wa.me/79896203189" target="_blank">
                                <img
                                    src={phoneIcon}
                                    alt="Phone Icon"
                                    className="icon"
                                />
                            </a>
                        </div>
                        <div className={cls.contacts}>
                            <p>
                                Режим работы: <span>пн-пт 09:00-18:00</span>
                            </p>

                            <p>
                                Номер телефона:{' '}
                                <a
                                    className="menu-list-icon-text-pointer"
                                    href="tel:+79289566198"
                                >
                                    +7 (928) 956-61-98
                                </a>
                            </p>
                            <p>
                                Email:{' '}
                                <a
                                    className="menu-list-icon-text-pointer"
                                    href="mailto:webteamstorm@gmail.com"
                                >
                                    webteamstorm@gmail.com
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={cls.bottom}>
                    <p className={cls.copy}>
                        Severtain © 2017-2024 | Все права защищены
                    </p>
                    <ul className={cls.nav}>
                        <li onClick={() => scrollToSection('projects')}>
                            Портфолио
                        </li>
                        <li>
                            <a
                                href="https://webteamstorm.ru/team/"
                                target="_blank"
                            >
                                О нас{' '}
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://webteamstorm.ru/docs/"
                                target="_blank"
                            >
                                Соглашения о сборе данных
                            </a>
                        </li>
                    </ul>
                </div>
            </Container>
        </div>
    );
};
