import cn from 'classnames';
import cls from './TariffStandard.module.scss';
import { Title } from '@/components/common/Title/Title';
import { PriceText } from '@/components/common/PriceText/PriceText';
import React from 'react';
import { Container } from '@/components/common/Container/Container';
import { ButtonModal } from '@/components/common/ButtonModal/ButtonModal';
import {
    tariffsStandard,
    tariffsStandardTwo,
} from '@/utils/constants/tariffs/tariffs';
import tariffBackground from '@/app/assets/images/background.png';

import decor from '@/app/assets/images/tariffs/standard-tariff.png';
import decorWebp from '@/app/assets/images/tariffs/standard-tariff.webp';

import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';

export const TariffStandard = (props) => {
    const { className } = props;
    const isDesctop = useMediaQuery({ query: '(min-width: 1211px)' });
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <div className={cn(className, cls.tarif)} ref={ref}>
            <Container className={cls.container} size={'medium'}>
                <div className={cls.wrapper}>
                    <div className={cls.header}>
                        <Title color={'white'} size={'smallTwo'}>
                            Стандартный
                        </Title>
                        <PriceText color={'beige'}>от 70 000 руб</PriceText>
                    </div>

                    <div className={cls.content}>
                        <div className={cls.left}>
                            <ul className={cls.list}>
                                {tariffsStandard.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>

                        <div className={cls.right}>
                            <ul className={cn(cls.list, cls.listRight)}>
                                {tariffsStandardTwo.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>

                            {isDesctop && (
                                <div className={cls.btnWrapper}>
                                    <ButtonModal
                                        color={'white'}
                                        selectedTariff={{
                                            name: 'Стандартный',
                                            price: 'от 70 000 руб',
                                        }}
                                    >
                                        Оставить заявку
                                    </ButtonModal>
                                </div>
                            )}
                        </div>
                    </div>

                    {!isDesctop && (
                        <div className={cls.btnWrapper}>
                            <ButtonModal
                                color={'white'}
                                selectedTariff={{
                                    name: 'Стандартный',
                                    price: 'от 70 000 руб',
                                }}
                            >
                                Оставить заявку
                            </ButtonModal>
                        </div>
                    )}
                </div>
            </Container>

            {inView && (
                <picture>
                    <source srcSet={decorWebp} type={'image/webp'} />
                    <img
                        width={839}
                        height={435}
                        className={cls.decor}
                        src={decor}
                        alt=""
                    />
                </picture>
            )}

            <img className={cls.imageBg} src={tariffBackground} alt="" />
        </div>
    );
};
