import cls from './Portfolio.module.scss';
import { Container } from '@/components/common/Container/Container';
import { PrimaryPortfolioSlider } from '@/components/common/PrimaryPortfolioSlider/PrimaryPortfolioSlider';
import { Title } from '@/components/common/Title/Title';
import { SecondaryPortfolioSlider } from '@/components/common/SecondaryPortfolioSlider/SecondaryPortfolioSlider';

export const Portfolio = () => {
    return (
        <section className={cls.section} id={'projects'}>
            <Container size={'small'}>
                <Title className={cls.title} color={'black'}>
                    Наши проекты
                </Title>
                <PrimaryPortfolioSlider />
            </Container>
            <SecondaryPortfolioSlider />
        </section>
    );
};
