import cn from 'classnames';
import cls from './Container.module.scss';

const paddingToClassVariant = {
    small: cls.small,
    medium: cls.medium,
};

export const Container = (props) => {
    const { children, className, size = '' } = props;

    const sizeClass = paddingToClassVariant[size];

    return (
        <div className={cn(className, cls.container, sizeClass)}>
            {children}
        </div>
    );
};
