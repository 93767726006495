export const questions = [
    {
        question: 'Сколько вариантов дизайна вы делаете в рамках договора?',
        answer: 'В зависимости от выбранного Вами тарифа, мы предоставляем на выбор до 3-х отличных друг от друга концептов. Подбираем референсы или опираемся на корпоративный стиль, если он есть. ',
    },
    {
        question: 'Какую вы предоставляете гарантию?',
        answer: 'Мы даем гарантию в рамках договора сроком до 6 месяцев. В нее входит устранение технических ошибок, которые могли быть допущены в ходе разработки. Срок исправления до 7 рабочих дней.',
    },
    {
        question: 'Какая у вас система оплаты и отчетность?',
        answer: 'Поэтапная предоплата, безнал, ИП. Регулярная демонстрация работы на наших серверах. Закрытие этапа по актам и утверждению с двух сторон.',
    },
    {
        question: 'Будет ли выделен отдельный менеджер под проект?',
        answer: 'Да, за каждым проектом закрепляется персональный менеджер для коммуникации и запроса данных.',
    },
    {
        question: 'Возможно ли дальнейшая разработка и поддержка?',
        answer: 'Да, мы предлагаем целый комплекс для готовых продуктов. Предлагаются персональные планы по поддержке и развитию сайтов.',
    },
];
