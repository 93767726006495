import cls from './Hero.module.scss';
import laptop from '@/app/assets/images/hero/laptop.jpg';
import { useMediaQuery } from 'react-responsive';
import { InfoBlock } from '@/components/common/InfoBlock/InfoBlock';
import {ButtonModal} from "@/components/common/ButtonModal/ButtonModal";
import decor from '@/app/assets/images/hero/decor.jpg';


export const Hero = () => {
    const isTablet = useMediaQuery({ query: '(max-width: 991px)' });

    return (
        <div className={cls.hero}>
            <div className={cls.left}>
                <h1 className={cls.title}>
                    Лендинг для <br /> вашего бизнеса <br />{' '}
                    <span>за 10 дней</span>{' '}
                </h1>
                <p className={cls.subtitle}>Гарантируем увеличение выручки!</p>
                <ButtonModal>Оставить заявку</ButtonModal>

                <img className={cls.imageDecor} src={decor}/>
            </div>
            <div className={cls.right}>
                {!isTablet && (
                    <InfoBlock />
                )}
                <img className={cls.imgLaptop} src={laptop} alt="Ноутбук" />
            </div>

            {isTablet && (
                <InfoBlock />
            )}
        </div>
    );
};
