import cn from 'classnames';
import cls from './Title.module.scss';

const mapSizeToClass = {
    small: cls.small,
    smallTwo: cls.smallTwo,
    average: cls.average,
};

const mapColorToClass = {
    black: cls.black,
    white: cls.white,
    blue: cls.blue,
};

const mapPositionToClass = {
    left: cls.left,
    center: cls.center,
    right: cls.right,
};

const mapSizeToHeaderTag = {
    smallTwo: 'h3',
    small: 'h3',
    average: 'h2',
};

export const Title = (props) => {
    const {
        className,
        children,
        size = 'average',
        color = 'blue',
        align = 'left',
    } = props;

    const HeaderTag = mapSizeToHeaderTag[size];
    const sizeClass = mapSizeToClass[size];
    const positionClass = mapPositionToClass[align];
    const colorClass = mapColorToClass[color];

    return (
        <HeaderTag
            className={cn(
                className,
                cls.title,
                sizeClass,
                colorClass,
                positionClass,
            )}
        >
            {children}
        </HeaderTag>
    );
};
