import work1Image from '@/app/assets/images/work-steps/work-1.png';
import work1ImageWebp from '@/app/assets/images/work-steps/work-1.webp';
import connectorLine1 from '@/assets/connector-line-1.svg';
import work2Image from '@/app/assets/images/work-steps/work-2.png';
import work2ImageWebp from '@/app/assets/images/work-steps/work-2.webp';
import connectorLine2 from '@/assets/connector-line-2.svg';
import work3Image from '@/app/assets/images/work-steps/work-3.png';
import work3ImageWebp from '@/app/assets/images/work-steps/work-3.webp';
import work4Image from '@/app/assets/images/work-steps/work-4.png';
import work4ImageWebp from '@/app/assets/images/work-steps/work-4.webp';
import work5Image from '@/app/assets/images/work-steps/work-5.png';
import work5ImageWebp from '@/app/assets/images/work-steps/work-5.webp';
import work6Image from '@/app/assets/images/work-steps/work-6.png';
import work6ImageWebp from '@/app/assets/images/work-steps/work-6.webp';
import work7Image from '@/app/assets/images/work-steps/work-7.png';
import work7ImageWebp from '@/app/assets/images/work-steps/work-7.webp';
import work8Image from '@/app/assets/images/work-steps/work-8.png';
import work8ImageWebp from '@/app/assets/images/work-steps/work-8.webp';

export const steps = [
    {
        id: 1,
        title: '1. Обсудим ваш проект',
        description:
            'В удобном для вас формате. Вышлем и изучим заполненный бриф и предложим оптимальное решение под бюджет и поставленные бизнес-цели.',
        image: work1Image,
        imageWebp: work1ImageWebp,
        connectorImage: connectorLine1,
        alt: 'Обсудим ваш проект'
    },
    {
        id: 2,
        title: '2. Подпишем договор',
        description:
            'Подготовим тех. документацию и календарный план разработки. Все прозрачно, вы будете знать, в какие сроки будет завершена та или иная стадия работ. Процесс регулярно демонстрируется на наших серверах, а также согласовывается с вами.',
        image: work2Image,
        imageWebp: work2ImageWebp,
        connectorImage: connectorLine2,
        alt: 'Подпишем договор'
    },
    {
        id: 3,
        title: '3. Погрузимся в задачу',
        description:
            'Проведем консультацию перед началом проекта, подберем референсы и создадим уникальный дизайн. Проанализируем рынок, изучим продукт и ЦА, чтобы выделить ваш продукт среди конкурентов.',
        image: work3Image,
        imageWebp: work3ImageWebp,
        connectorImage: connectorLine1,
        alt: 'Погрузимся в задачу'
    },
    {
        id: 4,
        title: '4. Разработаем дизайн',
        description:
            'Подготовим тех. документацию и календарный план разработки. Все прозрачно, вы будете знать, в какие сроки будет завершена та или иная стадия работ. Процесс регулярно демонстрируется на наших серверах, а также согласовывается с вами.',
        image: work4Image,
        imageWebp: work4ImageWebp,
        connectorImage: connectorLine2,
        alt: 'Разработаем дизайн'
    },
    {
        id: 5,
        title: '5. Реализуем верстку',
        description:
            'На основе анализа ниши, референсов и ваших пожеланий. Подготовим несколько уникальных вариантов. Согласуем концепт и контент, а при необходимости дополнительно создадим для вас продающий текст под поисковые системы.',
        image: work5Image,
        imageWebp: work5ImageWebp,
        connectorImage: connectorLine1,
        alt: 'Реализуем верстку'
    },
    {
        id: 6,
        title: '6. Подключим домен',
        description:
            'С поддержкой и гарантией до 12 месяцев. Следим за работоспособностью серверов, работой сайта, накоплением мусорных файлов, а также очисткой от вирусов и вредоносного ПО.',
        image: work6Image,
        imageWebp: work6ImageWebp,
        connectorImage: connectorLine2,
        alt: 'Подключим домен'
    },
    {
        id: 7,
        title: '7. Выпустим готовый проект',
        description:
            'Настроим админ-панель, почту и веб-аналитику. Поставим цели для отслеживания конверсии и проведем всестороннее тестирование перед запуском. Подключим необходимые сервисы, такие как чат-бот и подписная форму',
        image: work7Image,
        imageWebp: work7ImageWebp,
        connectorImage: connectorLine1,
        alt: 'Выпустим готовый проект'
    },
    {
        id: 8,
        title: '8. Настроим рекламу',
        description:
            'И приведем “горячих” клиентов, готовых приобрести ваш товар прямо сейчас. Оценим эффективность и масштабируем, для достижения новых показателей вашего бизнеса.',
        image: work8Image,
        imageWebp: work8ImageWebp,
        connectorImage: '',
        alt: 'Настроим рекламу'
    },
];
