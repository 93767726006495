import cn from 'classnames';
import cls from './PriceText.module.scss';

const mapColorToClass = {
    beige: cls.beige,
};

export const PriceText = (props) => {
    const { className, children, color = '' } = props;

    const colorClass = mapColorToClass[color];

    return <p className={cn(className, cls.text, colorClass)}>{children}</p>;
};
