import React, { useState, useEffect } from 'react';
import Modal from '../../common/Modal/Modal';
import arrowIcon from '../../../assets/arrow.svg';
import logoHeader from '../../../assets/logo-header.svg';
import '../../../styles/Header.css';
import {ButtonModal} from "@/components/common/ButtonModal/ButtonModal";

const DesktopHeader = () => {
    const [showServices, setShowServices] = useState(false);
    // const [servicesContainerStyle, setServicesContainerStyle] = useState({});

    const toggleServices = () => {
        setShowServices(!showServices);
        // setServicesContainerStyle({
        //     backgroundColor: showServices ? 'white' : '#F1F3FF',
        // });
    };
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            window.history.pushState({}, '', '#' + sectionId);
        }
    };
    useEffect(() => {
        const sectionId = window.location.hash.replace('#', '');
        if (sectionId) {
            setTimeout(() => {
                scrollToSection(sectionId);
            }, 100);
        }
    }, []);



    return (
        <div className="header-section">
            <div className="header-container">
                <div className="logo-container">
                    <img src={logoHeader} alt={'Логотип'} />
                </div>

                <div className="menu">
                    <div className="menu-list">
                        <div
                            className={
                                showServices
                                    ? 'menu-item menu-item-services active'
                                    : 'menu-item menu-item-services'
                            }
                            onClick={toggleServices}
                        >
                            <span className={showServices ? 'active' : ''}>
                                Услуги
                                <img
                                    src={arrowIcon}
                                    alt="Arrow"
                                    className={`arrow-icon ${showServices ? 'rotate' : ''}`}
                                />
                            </span>
                            {showServices && (
                                <div className="services-container">
                                    <ul className="services-list">
                                        <a
                                            href="https://mobapp.webteamstorm.ru/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {' '}
                                            <li>Разработка моб. приложений</li>
                                        </a>
                                        <a
                                            href="https://lp.webteamstorm.ru/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {' '}
                                            <li>Разработка одностраничников</li>
                                        </a>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div
                            className="menu-item menu-item-large"
                            onClick={() => scrollToSection('projects')}
                        >
                            Портфолио
                        </div>
                        <div
                            className="menu-item menu-item-large"
                            onClick={() => scrollToSection('reviews')}
                        >
                            Преимущества
                        </div>
                        <div
                            className="menu-item menu-item-large"
                            onClick={() => scrollToSection('tariff')}
                        >
                            Цены
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => scrollToSection('order-development')}
                        >
                            Контакты
                        </div>
                    </div>
                </div>
                <ButtonModal defaultButton={true}>
                    <button
                        className="menu-item request-button"
                    >
                        Связаться с нами
                    </button>
                </ButtonModal>
            </div>
        </div>
    );
};

export default DesktopHeader;
