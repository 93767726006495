import cn from 'classnames';
import cls from './TariffBasic.module.scss';
import { PriceText } from '@/components/common/PriceText/PriceText';
import { Title } from '@/components/common/Title/Title';
import { Container } from '@/components/common/Container/Container';
import React from 'react';
import { ButtonModal } from '@/components/common/ButtonModal/ButtonModal';
import { tariffsBasic } from '@/utils/constants/tariffs/tariffs';
import decor from '@/app/assets/images/tariffs/base-tariff.png';
import decorWebp from '@/app/assets/images/tariffs/base-tariff.webp';
import { useInView } from 'react-intersection-observer';

export const TariffBasic = (props) => {
    const { className } = props;
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <div className={cn(className, cls.tariff)} ref={ref}>
            <Container className={cls.wrapper} size={'medium'}>
                <div className={cls.header}>
                    <Title size={'smallTwo'}>Базовый</Title>
                    <PriceText>От 35 000 руб</PriceText>
                </div>

                <ul className={cls.list}>
                    {tariffsBasic.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>

                <div className={cls.btnWrapper}>
                    <ButtonModal
                        selectedTariff={{
                            name: 'Базовый',
                            price: 'От 35 000 руб',
                        }}
                    >
                        Оставить заявку
                    </ButtonModal>
                </div>
            </Container>

            {inView && (
                <picture>
                    <source srcSet={decorWebp} type={'image/webp'} />
                    <img
                        width={870}
                        height={490}
                        className={cls.decor}
                        src={decor}
                        alt={''}
                    />
                </picture>
            )}
        </div>
    );
};
