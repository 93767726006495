import kaiImage from '@/assets/kai.jpg';
import kaiImageWebp from '@/assets/kai.webp';
import tradeImage from '@/assets/trade.jpg';
import tradeImageWebp from '@/assets/trade.webp';
import fireImage from '@/assets/fire.jpg';
import fireImageWebp from '@/assets/fire.webp';
import skinclubImage from '@/assets/skinclub.jpg';
import skinclubImageWebp from '@/assets/skinclub.webp';
import kaiTabletImage from '@/assets/kai-tablet.jpg';
import kaiTabletImageWebp from '@/assets/kai-tablet.webp';
import tradeTabletImage from '@/assets/trade-tablet.jpg';
import tradeTabletImageWebp from '@/assets/trade-tablet.webp';
import fireTabletImage from '@/assets/fire-tablet.jpg';
import fireTabletImageWebp from '@/assets/fire-tablet.webp';
import skinclubTabletImage from '@/assets/skinclub-tablet.jpg';
import skinclubTabletImageWebp from '@/assets/skinclub-tablet.webp';
import kaiMobileImage from '@/assets/kai-mobile.jpg';
import kaiMobileImageWebp from '@/assets/kai-mobile.webp';
import tradeMobileImage from '@/assets/trade-mobil.jpg';
import tradeMobileImageWebp from '@/assets/trade-mobil.webp';
import fireMobileImage from '@/assets/fire-mobile.jpg';
import fireMobileImageWebp from '@/assets/fire-mobile.webp';
import skinclubMobileImage from '@/assets/skinclub-mobile.jpg';
import skinclubMobileImageWebp from '@/assets/skinclub-mobile.webp';
import kaiModalImage from '@/assets/kai-modal.png';
import kaiModalImageWebp from '@/assets/kai-modal.webp';
import tradeModalImage from '@/assets/trade-modal.jpg';
import tradeModalImageWebp from '@/assets/trade-modal.webp';
import fireModalImage from '@/assets/fire-modal.jpg';
import fireModalImageWebp from '@/assets/fire-modal.webp';
import skinclubModalImage from '@/assets/skinclub-modal.jpg';
import skinclubModalImageWebp from '@/assets/skinclub-modal.webp';

export const works = [
    {
        image: skinclubImage,
        imageWebp: skinclubImageWebp,
        imageTablet: skinclubTabletImage,
        imageTabletWebp: skinclubTabletImageWebp,
        imageMobile: skinclubMobileImage,
        imageMobileWebp: skinclubMobileImageWebp,
        imageModal: skinclubModalImage,
        imageModalWebp: skinclubModalImageWebp,
        alt: 'Slide 1',
        logoText: 'SkinClub - портал для розыгрыша скинов',
        tasksTitle: 'Поставленные задачи:',
        tasksList: [
            'Сложная система начисления призов',
            'Отслеживание рекородов',
            'Система интеграции по API',
            'Админ панель для управления',
            'Выдача предметов из Counter-Strike',
        ],
        duration: 'Срок выполнения работы: ',
        date: '40 дней',
        buttonText: 'Оставить заявку',
        projectLink:
            'https://webteamstorm.ru/projects/skin-club-odnostranichnuj-sajt-dlya-prognozov-counter-strike/',
    },
    {
        image: fireImage,
        imageWebp: fireImageWebp,
        imageTablet: fireTabletImage,
        imageTabletWebp: fireTabletImageWebp,
        imageMobile: fireMobileImage,
        imageMobileWebp: fireMobileImageWebp,
        imageModal: fireModalImage,
        imageModalWebp: fireModalImageWebp,
        alt: 'Slide 2',
        logoText: 'Одностраничник Center of Fireproof Tech',
        tasksTitle: 'Поставленные задачи:',
        tasksList: [
            'Мультиязычность',
            'Система управления',
            'Динамичный прайс лист',
            'Конверсия более 5%',
        ],
        duration: 'Срок выполнения работы: ',
        date: '20 дней',
        buttonText: 'Оставить заявку',
        projectLink:
            'https://webteamstorm.ru/projects/myltiyazuchnuyi_lending_protivopojarnuh_tehnologiyi_28/​​​​​​​',
    },
    {
        image: kaiImage,
        imageWebp: kaiImageWebp,
        imageTablet: kaiTabletImage,
        imageTabletWebp: kaiTabletImageWebp,
        imageMobile: kaiMobileImage,
        imageMobileWebp: kaiMobileImageWebp,
        imageModal: kaiModalImage,
        imageModalWebp: kaiModalImageWebp,
        alt: 'Slide 3',
        logoText: 'Введение в предпринимательство',
        tasksTitle: 'Поставленные задачи:',
        tasksList: [
            'Стильный и современный дизайн',
            'Синхронизация данных с внешнего сервиса',
            'Выбор графика для записи на тренинги',
            'Скорость загрузки менее 2 сек.',
        ],
        duration: 'Срок выполнения работы: ',
        date: '10 дней',
        buttonText: 'Оставить заявку',
        projectLink:
            'https://webteamstorm.ru/projects/lending_vvedenie_v_predprinimatelstvo_10/',
    },
    {
        image: tradeImage,
        imageWebp: tradeImageWebp,
        imageTabletWebp: tradeTabletImageWebp,
        imageMobileWebp: tradeMobileImageWebp,
        imageModalWebp: tradeModalImageWebp,
        alt: 'Slide 4',
        logoText: 'Оказание финансовый услуг трейдинвест',
        tasksTitle: 'Поставленные задачи:',
        tasksList: [
            'Оперирование с динамическими курсами',
            'Синхронизация со счетами по API',
            'Настраиваемый калькулятор стоимости',
            'Связь с CRM для отправки заявок',
            'Низкий показатель отказов (менее 10%)',
        ],
        duration: 'Срок выполнения работы: ',
        date: '15 дней',
        buttonText: 'Оставить заявку',
        projectLink:
            'https://webteamstorm.ru/projects/odnostranichnuyi_lending_treyid-invest_31/',
    },
];
