import cls from './ProjectItem.module.scss';
import imgLoop from '@/app/assets/images/portfolio-slider/loop.png';
import { useMediaQuery } from 'react-responsive';
import { Title } from '@/components/common/Title/Title';
import { ButtonModal } from '@/components/common/ButtonModal/ButtonModal';
import { useInView } from 'react-intersection-observer';
import React, { useEffect } from 'react';

export const ProjectItem = (props) => {
    const isTablet = useMediaQuery({ query: '(max-width: 991px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const {
        image,
        imageWebp,
        logoText,
        imageTablet,
        imageTabletWebp,
        tasksTitle,
        tasksList,
        date,
        alt,
        imageModal,
        imageModalWebp,
    } = props;

    const renderImage = () => {
        if (isMobile) {
            return (
                <picture>
                    <source srcSet={imageWebp} type={'image/webp'} />
                    <img className={cls.image} src={image} alt={alt} />
                </picture>
            );
        } else if (isTablet) {
            return (
                <picture>
                    <source
                        width={'506'}
                        height={'400'}
                        srcSet={imageTabletWebp}
                        type={'image/webp'}
                    />
                    <img
                        width={'506'}
                        height={'400'}
                        src={imageTablet}
                        alt={alt}
                    />
                </picture>
            );
        }

        return (
            <picture>
                <source srcSet={imageWebp} type={'image/webp'} />
                <img className={cls.image} src={image} alt={alt} />
            </picture>
        );
    };

    return (
        <div className={cls.item} ref={ref}>
            <div className={cls.wrappeqrImage}>
                {inView && renderImage()}
                <ButtonModal
                    type={'button'}
                    defaultButton={true}
                    isWebsiteModal={true}
                    websiteUrl={imageModalWebp}
                >
                    {inView && (
                        <img
                            loading="lazy"
                            className={cls.magnifying}
                            src={imgLoop}
                            alt="Увеличение"
                        />
                    )}
                </ButtonModal>
            </div>
            <div className={cls.content}>
                <Title className={cls.title} size={'small'} color={'blue'}>
                    {logoText}
                </Title>

                <p className={cls.titleTask}>{tasksTitle}</p>

                <ul className={cls.listTask}>
                    {tasksList.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>

                <p className={cls.deadline}>
                    Срок выполнения работы: <span>{date}</span>{' '}
                </p>

                <ButtonModal>Оставить заявку</ButtonModal>
            </div>
        </div>
    );
};
