import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { ProjectItem } from '../ProjectItem/ProjectItem.jsx';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import useMediaQuery from 'react-responsive';

import arrowSlider from '@/app/assets/images/icons/arrowSlider.svg';
import cls from './PrimaryPortfolioSlider.module.scss';
import { works } from '@/utils/constants/portfolio/works';

export const PrimaryPortfolioSlider = (props) => {
    const { className } = props;
    const [active, setActive] = useState(false);
    const [isShowPrev, setIsShowPrev] = useState(false);
    const [isShowNext, setIsShowNext] = useState(false);
    const swiperRef = useRef();
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    useEffect(() => {
        setIsShowPrev(true);
    }, [isMobile]);

    const onChangeSlider = (swiper) => {
        if (isMobile) {
            setIsShowPrev(true);
            setIsShowNext(false);
        } else {
            if (swiper.slides.length - 1 === swiper.activeIndex) {
                setIsShowNext(true);
            } else {
                setIsShowNext(false);
            }

            if (!swiper.activeIndex) {
                setIsShowPrev(true);
            } else {
                setIsShowPrev(false);
            }
        }
    };

    return (
        <div className={className}>
            <div className={cls.swiper}>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={50}
                    slidesPerView={1}
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    loop={isMobile ? true : false}
                    onSwiper={() => console.log('onSWIPER')}
                    onSlideChange={(swiper) => onChangeSlider(swiper)}
                    className={cls.swiper}
                >
                    {works.map((item) => (
                        <SwiperSlide key={item.logoText}>
                            <ProjectItem
                                image={item.image}
                                imageWebp={item.imageWebp}
                                imageTablet={item.imageTablet}
                                imageTabletWebp={item.imageTabletWebp}
                                tasksTitle={item.tasksTitle}
                                logoText={item.logoText}
                                date={item.date}
                                tasksList={item.tasksList}
                                alt={item.alt}
                                imageModal={item.imageModal}
                                imageModalWebp={item.imageModalWebp}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>

                <button
                    onClick={() => swiperRef.current?.slidePrev()}
                    className={cn(
                        cls.sliderController,
                        cls.sliderControllerPrev,
                        {
                            [cls.isShowPrev]: isShowPrev,
                        },
                    )}
                >
                    <img src={arrowSlider} alt={'Кнопка назад'} />
                </button>
                <button
                    onClick={() => swiperRef.current?.slideNext()}
                    className={cn(
                        cls.sliderController,
                        cls.sliderControllerNext,
                        {
                            [cls.isShowNext]: isShowNext,
                        },
                    )}
                >
                    <img src={arrowSlider} alt={'Кнопка вперёд'} />
                </button>
            </div>
        </div>
    );
};
