import cn from 'classnames';
import cls from './ProjectCard.module.scss';
import React from 'react';
import { useInView } from 'react-intersection-observer';

export const ProjectCard = (props) => {
    const {
        className,
        image,
        imageWebp,
        title,
        alt,
        date,
        duration,
        projectLink,
    } = props;
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <>
            <div className={cn(className, cls.card)} ref={ref}>
                {inView && (
                    <picture>
                        <source
                            width={'506'}
                            height={'400'}
                            srcSet={imageWebp}
                            type={'image/webp'}
                        />
                        <img
                            width={'506'}
                            height={'400'}
                            className={cls.image}
                            src={image}
                            alt={alt}
                        />
                    </picture>
                )}

                <a className={cls.link} href={projectLink} target={'_blank'}>
                    Смотреть проект
                </a>

                <div className={cls.content}>
                    <h4 className={cls.title}>{title}</h4>
                    <p className={cls.subtitle}>
                        {duration} {date}{' '}
                    </p>
                </div>
            </div>
        </>
    );
};
