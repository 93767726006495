import React from 'react';
import DesktopHeader from './DesktopHeader';
import TabletHeader from './TabletHeader';
import MobileHeader from './MobileHeader';
import { useMediaQuery } from 'react-responsive';

const Header = () => {
  const isDescktop = useMediaQuery({ query: '(min-width: 992px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 701px)' });


  if (isDescktop) {
    return  <DesktopHeader />
  }

  if (isTablet) {
    return  <TabletHeader />
  }

  return  <MobileHeader />
};

export default Header;

