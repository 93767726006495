import cn from 'classnames';
import cls from './TariffAdvanced.module.scss';
import { Container } from '@/components/common/Container/Container';
import { Title } from '@/components/common/Title/Title';
import { PriceText } from '@/components/common/PriceText/PriceText';
import React from 'react';
import { ButtonModal } from '@/components/common/ButtonModal/ButtonModal';
import {
    tariffsAdvanced,
    tariffsAdvancedTwo,
} from '@/utils/constants/tariffs/tariffs';
import { useInView } from 'react-intersection-observer';
import decor from '@/app/assets/images/tariffs/advanced-tariff.png';
import decorWebp from '@/app/assets/images/tariffs/advanced-tariff.webp';

export const TariffAdvanced = (props) => {
    const { className } = props;
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <div className={cn(className, cls.tariff)} ref={ref}>
            <Container className={cls.wrapper} size={'medium'}>
                <div className={cls.header}>
                    <Title size={'smallTwo'}>Продвинутый</Title>
                    <PriceText>Индивидуально</PriceText>
                </div>

                <div className={cls.content}>
                    <div className={cls.left}>
                        <ul className={cls.list}>
                            {tariffsAdvanced.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={cls.right}>
                        <ul className={cls.list}>
                            {tariffsAdvancedTwo.map((item, idx) => (
                                <li
                                    key={idx}
                                    className={cn({
                                        [cls.orange]: idx < 4,
                                    })}
                                >
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={cls.btn}>
                    <ButtonModal
                        selectedTariff={{
                            name: 'Продвинутый',
                            price: 'Индивидуально',
                        }}
                    >
                        Оставить заявку
                    </ButtonModal>
                </div>
            </Container>
            {inView && (
                <picture>
                    <source srcSet={decorWebp} type={'image/webp'} />
                    <img
                        width={817}
                        height={459}
                        className={cls.decor}
                        src={decor}
                        alt={''}
                    />
                </picture>
            )}
        </div>
    );
};
