import cn from 'classnames';
import cls from './Feedback.module.scss';
import { Container } from '@/components/common/Container/Container';
import { Title } from '@/components/common/Title/Title';
import rightArrowIcon from '@/assets/form-arrow-to-the-right.svg';
import leftArrowIcon from '@/assets/form-arrow-to-the-left.svg';
import React from 'react';
import tgIcon from '@/assets/form-icon-tg.svg';
import skypeIcon from '@/assets/form-icon-skype.svg';
import phoneIcon from '@/assets/form-icon-phone.svg';
import { FeedbackForm } from '@/components/common/FeedbackForm/FeedbackForm';
import feedbackBackground from '@/app/assets/images/background.png';
import { useInView } from 'react-intersection-observer';

export const Feedback = (props) => {
    const { className } = props;

    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <div
            ref={ref}
            className={cn(className, cls.feedback)}
            id={'order-development'}
        >
            <div className={cls.header}>
                <img src={rightArrowIcon} alt="Right Arrow" />
                <Title className={cls.title} color={'white'} align={'center'}>
                    ещё остались вопросы?{' '}
                </Title>
                <img src={leftArrowIcon} alt="Left Arrow" />
            </div>

            <Container className={cls.feedbackContainer}>
                <div className={cls.wrapper}>
                    <div className={cls.info}>
                        <div className={cls.emailWrapper}>
                            <p>Напишите нам :</p>
                            <a href={'mailto:company@wsteam.com.ru'}>
                                company@wsteam.com.ru
                            </a>
                        </div>

                        <p className={cls.text}>Ответим на все вопросы:</p>

                        <div className={cls.socials}>
                            <a href="https://t.me/severtain" target="_blank">
                                <img src={tgIcon} alt="Telegram" />
                            </a>
                            <a href="skype:severtaindev?chat" target="_blank">
                                <img src={skypeIcon} alt="Skype" />
                            </a>
                            <a href="https://wa.me/79896203189" target="_blank">
                                <img src={phoneIcon} alt="Phone" />
                            </a>
                        </div>
                    </div>
                    <FeedbackForm />
                </div>
            </Container>

            <img className={cls.imageBg} src={feedbackBackground} alt="" />
        </div>
    );
};
