export const SvgSelector = (props) => {
    const { id } = props;

    switch (id) {
        case 'arrow-small':
            return (
                <svg
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M30 12.0002L18 23.0002L7.00002 12.0002C6.79282 11.803 6.28967 12.0002 6.00002 12.0002C5.71038 12.0002 5.20723 11.803 5.00002 12.0002C4.8997 12.096 5.05444 12.8741 5.00002 13.0002C4.94561 13.1264 5.00002 12.8634 5.00002 13.0002C4.94561 12.8741 5.00002 13.1371 5.00002 13.0002C5.05444 13.1264 4.8997 13.9045 5.00002 14.0002L18 26.0002C17.6975 26.0002 18.2167 26.206 18 26.0002C18.3026 26.0002 18.7834 26.206 19 26.0002L32 14.0002C32.1011 13.9044 31.9452 13.1268 32 13.0002C32 13.1376 32.0549 12.8737 32 13.0002C32 12.8628 32.0549 13.1268 32 13.0002C31.9452 12.8737 32.1011 12.0961 32 12.0002C31.7928 11.803 31.2897 12.0002 31 12.0002C30.7104 12.0002 30.2072 11.803 30 12.0002Z"
                        fill="#9C95AE"
                    />
                </svg>
            );
        default:
            break;
    }
};
