import cn from 'classnames';
import cls from './WorkSteps.module.scss';
import { Title } from '@/components/common/Title/Title';
import { Container } from '@/components/common/Container/Container';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { useMediaQuery } from 'react-responsive';
import { steps } from '@/utils/constants/steps/steps';
import { useInView } from 'react-intersection-observer';
import React from "react";

export const WorkSteps = (props) => {
    const { className } = props;
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <section className={cn(className, cls.work)} id={'reviews'} ref={ref}>
            <Container>
                <div className={cls.box}>
                    <div className={cls.header}>
                        <Title className={cls.title}>Как мы работаем</Title>
                        <p className={cls.text}>
                            Доведем идею до совершенства и реализуем лучший
                            проект в вашей нише!
                        </p>
                    </div>

                    {isMobile ? (
                        <Accordion allowZeroExpanded>
                            {steps.map((question, index) => (
                                <AccordionItem
                                    className={cls.tab}
                                    key={question.id}
                                >
                                    <AccordionItemHeading>
                                        <AccordionItemButton
                                            className={cls.accardionBtn}
                                        >
                                            <p className={cls.questionTitle}>
                                                {question.title}
                                            </p>
                                            {steps.length - 1 !== index && <img
                                                width={764}
                                                height={396}
                                                className={cls.imageLinear}
                                                src={
                                                    question.connectorImage
                                                }
                                            />}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className={cls.accardionContent}>
                                            <p className={cls.description}>
                                                {question.description}
                                            </p>
                                            {inView && <picture>
                                                <source srcSet={question.imageWebp} type={'image/webp'}/>
                                                <img src={question.image} alt={question.alt}/>
                                            </picture>}
                                        </div>

                                        <img
                                            className={cls.imageLinear}
                                            src={
                                                question.connectorImage
                                            }
                                        />
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    ) : (
                        <Tabs>
                            <div className={cls.wrapper}>
                                <div className={cls.questions}>
                                    <TabList>
                                    {steps.map((question, key) => (
                                            <Tab
                                                className={cls.tab}
                                                key={question.id}
                                            >
                                                <p
                                                    className={
                                                        cls.questionTitle
                                                    }
                                                >
                                                    {question.title}
                                                </p>
                                               <img
                                                    className={cls.imageLinear}
                                                    src={
                                                        question.connectorImage
                                                    }
                                                />
                                            </Tab>
                                        ))}
                                    </TabList>
                                </div>
                                <div className={cls.answers}>
                                    {steps.map((question, key) => (
                                        <TabPanel key={question.id}>
                                            <p className={cls.description}>
                                                {question.description}
                                            </p>
                                            {inView && <picture>
                                                <source srcSet={question.imageWebp} type={'image/webp'}/>
                                                <img src={question.image} alt={question.alt}/>
                                            </picture>}
                                        </TabPanel>
                                    ))}
                                </div>
                            </div>
                        </Tabs>
                    )}
                </div>
            </Container>
        </section>
    );
};
