import cn from 'classnames';
import { FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ProjectCard } from '@/components/common/ProjectCard/ProjectCard';
import cls from './SecondaryPortfolioSlider.module.scss';
import 'swiper/css';
import { works } from '@/utils/constants/portfolio/works-two';

export const SecondaryPortfolioSlider = (props) => {
    const { className } = props;

    return (
        <div className={cn(className, cls.content)}>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={20}
                modules={[FreeMode]}
                freeMode={true}
                className={cls.swiper}
            >
                {works.map((item, i) => (
                    <SwiperSlide key={i} className={cls.slide}>
                        <ProjectCard
                            projectLink={item.projectLink}
                            duration={item.duration}
                            date={item.date}
                            alt={item.alt}
                            title={item.title}
                            image={item.image}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
