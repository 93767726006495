import Header from '@/components/Layout/Header/Header';
import { Portfolio } from '@/components/Home/Portfolio/Portfolio';
import { Tariffs } from '@/components/Home/Tariffs/Tariffs';
import { Questions } from '@/components/Home/Questions/Questions';
import { WorkSteps } from '@/components/Home/WorkSteps/WorkSteps';
import { Feedback } from '@/components/Home/Feedback/Feedback';
import { Footer } from '@/components/Layout/Footer/Footer';
import { Hero } from '@/components/Home/Hero/Hero';

function HomePage() {
    return (
        <>
            <Header />
            <Hero />
            <Portfolio />
            <WorkSteps />
            <Tariffs />
            <Questions />
            <Feedback />
            <Footer />
        </>
    );
}

export default HomePage;
