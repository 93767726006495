import { Button } from '@/components/common/Button/Button';
import Modal from '@/components/common/Modal/Modal';
import React, { useState, useEffect } from 'react';
import { Portal } from '@/components/common/Portal/Portal';
import WebsiteModal from '@/components/common/Modal/WebsiteModal';

export const ButtonModal = (props) => {
    const {
        children,
        defaultButton = false,
        websiteUrl = '',
        isWebsiteModal = false,
        selectedTariff = null,
        ...otherProps
    } = props;
    const [showModal, setShowModal] = useState(false);

    const onToggleModal = () => {
        setShowModal(!showModal);
    };

    const renderButton = () => {
        if (defaultButton) {
            return (
                <button onClick={onToggleModal} {...otherProps}>
                    {children}
                </button>
            );
        }

        return (
            <Button onClick={onToggleModal} {...otherProps}>
                {children}
            </Button>
        );
    };

    const renderModal = () => {
        if (isWebsiteModal) {
            return <WebsiteModal url={websiteUrl} onClose={onToggleModal} />;
        }

        return (
            <Modal onClose={onToggleModal} selectedTariff={selectedTariff} />
        );
    };

    useEffect(() => {
        const body = document.querySelector('body');
        const header = document.querySelector('.header-section');

        if (showModal) {
            const scrollBarWidth =
                window.innerWidth - document.body.offsetWidth;
            body.style.overflow = 'hidden';
            document.body.style.paddingRight = `${scrollBarWidth}px`;
            if (header) {
                header.style.paddingRight = `${scrollBarWidth}px`;
            }
        } else {
            body.style.overflow = '';
            document.body.style.paddingRight = `0px`;

            if (header) {
                header.style.paddingRight = `0px`;
            }
        }

        return () => {
            body.style.overflow = 'visible';
        };
    }, [showModal]);

    return (
        <>
            {renderButton()}
            {showModal && <Portal>{renderModal()}</Portal>}
        </>
    );
};
