import React, { useState } from 'react';
import Modal from '../../common/Modal/Modal';
import arrowIcon from '../../../assets/arrow.svg';
import '../../../styles/Header.css';
import logoHeader from '../../../assets/logo-header.svg';
import {ButtonModal} from "@/components/common/ButtonModal/ButtonModal";

const TabletHeader = () => {
    const [showServices, setShowServices] = useState(false);
    const [servicesContainerStyle, setServicesContainerStyle] = useState({});

    const toggleServices = () => {
        setShowServices(!showServices);
        setServicesContainerStyle({
            backgroundColor: showServices ? 'white' : '#F1F3FF',
        });
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <>
            <div className="header-section header-section-large">
                <div className="logo-button-container">
                    <div className="logo-container">
                        <img src={logoHeader} alt={'Логотип'} />
                    </div>
                    <ButtonModal defaultButton={true}>
                        <button
                            className="menu-item request-button"
                        >
                            Связаться с нами
                        </button>
                    </ButtonModal>
                </div>
            </div>
            <div className="header-section header-section-small">
                <div className="menu">
                    <div className="menu-list">
                        <div
                            className="menu-item"
                            onClick={toggleServices}
                            style={servicesContainerStyle}
                        >
                            Услуги
                            <img
                                src={arrowIcon}
                                alt="Arrow"
                                className={`arrow-icon ${showServices ? 'rotate' : ''}`}
                            />
                            {showServices && (
                                <div className="services-container">
                                    <ul className="services-list">
                                        <a
                                            href="https://mobapp.webteamstorm.ru/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {' '}
                                            <li>Разработка моб. приложений</li>
                                        </a>
                                        <a
                                            href="https://lp.webteamstorm.ru/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {' '}
                                            <li>Разработка одностраничников</li>
                                        </a>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => scrollToSection('projects')}
                        >
                            Портфолио
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => scrollToSection('reviews')}
                        >
                            Преимущества
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => scrollToSection('tariff')}
                        >
                            Цены
                        </div>
                        <div
                            className="menu-item"
                            onClick={() => scrollToSection('order-development')}
                        >
                            Контакты
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TabletHeader;
