import cn from 'classnames';
import cls from './Questions.module.scss';
import { Title } from '@/components/common/Title/Title';
import { Container } from '@/components/common/Container/Container';
import questionImg from '@/app/assets/images/Questions/question.png';
import questionImgWebp from '@/app/assets/images/Questions/question.webp';

import { QuestionList } from '@/components/common/QuestionList/QuestionList';
import { useInView } from 'react-intersection-observer';
import React from 'react';

export const Questions = (props) => {
    const { className } = props;

    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <section className={cn(className, cls.questions)} ref={ref}>
            <Container>
                <div className={cls.wrapper}>
                    <div className={cls.header}>
                        <Title>Вопрос — ответ</Title>
                    </div>

                    <div className={cls.content}>
                        <QuestionList />
                    </div>
                </div>

                <div className={cls.questionImg}>
                    {inView && (
                        <picture>
                            <source
                                srcSet={questionImgWebp}
                                type={'image/webp'}
                            />
                            <img
                                width={586}
                                height={695}
                                src={questionImg}
                                alt=""
                            />
                        </picture>
                    )}
                </div>
            </Container>
        </section>
    );
};
